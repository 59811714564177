import React from 'react';
import styled from 'styled-components';

import { Block, Flex } from '../../../components/styled';
import { breakpoints } from '../../../components/styled/variables';

interface Value {
  title: string;
  text: string;
}

const values: Value[] = [
  {
    title: 'Be humble',
    text: 'We’re a team of learners. It’s more important for us to build the best product possible than to always be right. We listen to alternative opinions, let things go (even when we love them) and leave our egos at the door.',
  },
  {
    title: 'Be honest',
    text: 'We’re open and honest in everything we do. We don’t hide behind language or tell half-truths. We don’t pass the buck or make excuses. We’re continuously building on our founding principles of trust and transparency; we know they’re essential to get us where we’re going.',
  },
  {
    title: 'Be adaptable',
    text: 'We’re always listening — to our customers, to the market and to each other. We’re adaptable, agile, and open to trying new things. We never waver in our core mission, but we’re creative about how we get there.',
  },
  {
    title: 'Be encouraging',
    text: 'We’re empowering the next generation of innovation and that means empowering the people behind them, too. We work together and we help each other out, because we want everyone to benefit from the ecosystem we’re building. We create a space for people to succeed.',
  },
  {
    title: 'Be a leader',
    text: 'We believe that passion breeds the best results. We stand up for what we believe in, run with ideas, and see projects through to the very end. We own our work, and we take pride in it.',
  },
];

export default function Values() {
  return (
    <Block margin="0 40px 40px 0">
      <Flex columns={2} flow="row wrap">
        {values.map(({ title, text }: Value) => (
          <Block key={title} width="470px">
            <ValueTitle>{title}</ValueTitle>
            <ValueText>{text}</ValueText>
          </Block>
        ))}
      </Flex>
    </Block>
  );
}

const ValueTitle = styled.h3`
  margin-bottom: 0.8em;

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 22px;
  }

  @media (min-width: ${breakpoints.above13inch}) {
    font-size: 24px;
  }
`;

const ValueText = styled.p`
  line-height: 1.56;
`;
