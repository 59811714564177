import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import Values from './components/values';
import { Layout } from '../../layout';
import {
  Block,
  Heading,
  Flex,
  LargeText,
  Section as StyledSection,
  Strong,
} from '../../components/styled';

interface AboutSection {
  title: string;
  text: string;
  link?: {
    to: string;
    text: string;
  };
  children?: React.ReactNode;
  subsection?: React.ReactNode;
}

type SectionProps = AboutSection;

const Section = ({ title, text, link, children, subsection }: SectionProps) => (
  <>
    <Flex key={title}>
      <Heading>{title}</Heading>
      <Block width="64%" margin="0 0 40px">
        <LargeText as="p">{text}</LargeText>
        {link && (
          <Strong as="p" weight={500}>
            <Link to={link.to}>{link.text}</Link>
          </Strong>
        )}
        {subsection}
      </Block>
    </Flex>
    {children}
  </>
);

export default function About() {
  const { site } = useStaticQuery(aboutQuery);
  const { employees } = site.siteMetadata.company;

  const aboutSections: AboutSection[] = [
    {
      title: 'Our values',
      text: 'At Fidel API, we live by our values and what we stand for, and that feeds into every decision we make. Fidel comes from the Latin word ‘Fidelis’ which means reliability, trust, truth and dependability. As a financial infrastructure API provider, we have a responsibility to be reliable, dependable and trustworthy. We honor those values — and our commitment to them — by naming ourselves after the ancient root word itself.',
      children: <Values />,
    },
    {
      title: 'Our work',
      text: 'Fidel API’s role in the ecosystem is to help developers build innovative experiences connected to purchases made using a payment card. Our APIs provide a single point of integration to build on top of card transactions so developers can create engaging experiences around the moment a user’s card is swiped, dipped, tapped or typed. We’re helping unlock the potential of payment cards, thereby transforming the way users interact with merchants and service providers around the world.',
      subsection: (
        <Flex justifyContent="flex-start" gap="6px">
          <Strong as="p" weight={500}>
            See our products:
          </Strong>
          <Strong as="p" weight={500}>
            <Link to="/products/transaction-stream/">
              Transaction Stream API
            </Link>
          </Strong>
          <Strong as="p" weight={500}>
            &
          </Strong>
          <Strong as="p" weight={500}>
            <Link to="/products/select-transactions">
              Select Transactions API
            </Link>
          </Strong>
        </Flex>
      ),
    },
    {
      title: 'Our people',
      text: `We’re a team of ${employees.count} all focused on one vision — unlocking the full potential of payments cards. Between us, we speak ${employees.languages} languages and represent ${employees.nationalities} different nationalities. It’s our diversity of background, thinking, talents and skills that allows us to build truly global products for the developers who are driving payments innovation forward.`,
      link: { text: 'View careers', to: '/careers' },
    },
  ];

  return (
    <Layout title="We’re building tools to power payments innovation, globally">
      <StyledSection
        maxWidth={1360}
        paddingTablet="40px 30px 100px"
        paddingDesktop="60px 116px 30px"
      >
        <Flex>
          <Block width="28%">
            <Heading weight={300} lineHeight="1.47" style={{ marginBottom: 0 }}>
              Our mission
            </Heading>
            <LargeText>
              <p style={{ margin: 0 }}>
                Unlock the full potential of payment cards.
              </p>
            </LargeText>
          </Block>
          <Block width="64%">
            <LargeText>
              <p>
                Real-time payment data can be incredibly powerful. However, the
                payments ecosystem can be highly complex to navigate. We decided
                to do something about it.
              </p>
              <p>
                We offer the only suite of financial infrastructure APIs that
                enable developers to build programmable experiences connected to
                purchases made in real-time using a card. Our tools are creating
                new value and transforming the way users and merchants interact.
              </p>
            </LargeText>
          </Block>
        </Flex>
      </StyledSection>
      <StyledSection
        maxWidth={1360}
        paddingTablet="40px 30px 100px"
        paddingDesktop="60px 116px 30px"
        bgColor="#f8f8f8"
      >
        {aboutSections.map(
          ({ text, title, link, children, subsection }, id) => (
            <Section
              key={id}
              title={title}
              text={text}
              link={link}
              subsection={subsection}
            >
              {children}
            </Section>
          ),
        )}
      </StyledSection>
    </Layout>
  );
}

const aboutQuery = graphql`
  query {
    site {
      siteMetadata {
        company {
          employees {
            count
            nationalities
            languages
          }
        }
      }
    }
  }
`;
